<template>
   <div class="cont">
      <el-row>
         <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack">
            <span>同步人脸识别设备</span>
         </el-link>
      </el-row>
      <!-- 表头 -->
      <el-row>
         <span class="label" v-text="$t('msg.building_unit_a')">楼栋/单元：</span>
         <el-cascader

                 v-model="buildUnitIds"
                 :options="buildList"
                 @change="changeBuilding"
                 clearable
                 class="width-165 m-right-10"></el-cascader>
         <!--<span class="label">楼层/房间：</span>
         <el-select class="width-175 m-bottom-5 m-right-10" v-model="roomNoQ"  placeholder="请选择">
            <el-option
                    v-for="item in roomList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
         </el-select>-->
         <span class="label">设备编号：</span>
         <el-input class="width-175 m-bottom-5 m-right-10" placeholder="设备编号" v-model="deviceNo"  clearable></el-input>
         <el-button class="m-bottom-5" type="primary" icon="el-icon-search" @click="handleQuery" ><span v-text="$t('msg.search')">搜索</span></el-button>
         <el-button class="m-bottom-5" type="primary" icon="el-icon-refresh-right" @click="handleQuery"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
         <el-button class="m-bottom-5" type="primary"  @click="handleSynchroDevice" plain style="float: right"><span>同步设备</span></el-button>
      </el-row>
      <!-- 表格 -->
      <div class="table-box">
         <el-table
                 :data="tableData"
                 style="width: 100%"
                 :stripe="true"
                 >
            <el-table-column label="序号" width="60">
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column prop="deviceNo" label="设备编号" min-width="140"></el-table-column>
            <el-table-column prop="deviceCategory" label="设备名称" min-width="100"></el-table-column>
            <el-table-column prop="deviceType" label="设备分类" min-width="90"></el-table-column>
            <el-table-column prop="deviceArea" label="安装位置" min-width="160"></el-table-column>
            <el-table-column prop="companyName" label="在线状态" min-width="120"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="140" sortable></el-table-column>
            <el-table-column label="绑定房间" fixed="right" min-width="160">
               <template slot-scope="scope">
                  <el-select  @focus="getRoomList(scope.row.id)" @change="val => handleChange(scope.row.deviceNo, val)" v-model="scope.row.roomNo" placeholder="请选择">
                     <el-option
                             v-for="item in scope.row.roomList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                     </el-option>
                  </el-select>
               </template>
            </el-table-column>
         </el-table>
      </div>
   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   export default {
      name: 'Hotel',
      data () {
         return {
            tableData: [],
            buildList: [],      // 楼栋列表
            buildUnitIds: [],   // 楼栋ID
            roomList: [],
            page: 1,
            limit: 10,
            deviceNo: '',
            roomNoQ: '',
            roomNo: '',
            bindRoom_success: '绑定房间成功！',
            synchro_success: '同步设备成功！',
         }
      },
      beforeDestroy() {
         sessionStorage.removeItem('devices')
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1 // 每页显示数
         this.tableData = JSON.parse(sessionStorage.getItem('devices'))
         this.hotelId = this.$route.query.hotelId
         this.getBuildings()
      },
      methods: {
         // 获取同步设备
         getSynchroDevice(){
            const url = urlObj.synchroDevices + `?limit=${this.limit}&page=${this.page}`
            const param = {
               deviceNo: this.deviceNo,
               roomNo: this.roomNo,
               hotelId: this.hotelId
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.tableData = res.records
               }
            })
         },
         // 搜索设备
         handleQuery(bool){
            if (bool) return this.getSynchroDevice()
            this.deviceNo = ''
            this.roomNo = ''
         },
         // 获取楼栋列表
         getBuildings(){
            const url = urlObj.buildAndUnitList + `/${this.hotelId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildList = res.records
                  this.buildList = buildList.map(item1 => {
                     let children = []
                     if (item1.buildUnits.length > 0){
                        children = item1.buildUnits.map(item2 => {
                           return {value: item2.id, label: item2.unitName}
                        })
                     }
                     return {value: item1.id, label: item1.buildName, children}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取楼栋单元层级
         changeBuilding(val){
            this.buildUnitId = val[1]
            this.getRoomList()
         },
         // 获取房间列表
         getRoomList(id) {
            const url = urlObj.getRoomLists
            const param = {
               hotelId: this.hotelId,
               buildUnitId: this.buildUnitId,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let roomList = res.records
                  this.roomList = roomList.map(item => {
                     return {value: item.id + ',' + item.roomNo, label: item.roomNo}
                  })
                  this.tableData.forEach(item => {
                     if (item.id === id){
                        this.$set(item, 'roomList', this.roomList)
                     }
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 绑定房间
         handleChange(deviceNo, roomIdOrNo){
            const url = urlObj.bindRoom
            const param = {
               roomId: roomIdOrNo.split(',')[0],
               roomNo: roomIdOrNo.split(',')[1],
               deviceNo
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.bindRoom_success,
                     type: 'success'
                  })
               }
            })
         },
         // 同步设备
         handleSynchroDevice(){
            const url = urlObj.synchronousDevice
            const param = {
               hotelId: this.hotelId,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.synchro_success,
                     type: 'success'
                  })
               }
            })
         },
         // 改变每页数
         pageChange(num){
            this.limit = num
            this.getSynchroDevice()
         },
         // 改变当前页
         currChange(num){
            this.page = num
            this.getSynchroDevice()
         },
         // 返回上页
         goBack(){
            this.$router.replace('/hotel_manage')
         },
      }
   }
</script>
<style scoped lang="scss">
</style>
